import { eventHandlers } from '@services/dataworld/DataWorld.handlers.js';
import { nextTick } from '@utils/nextTick.js';

export const exportDataWorld = dataWorld => {
  const out = {};

  out.dataSets = dataWorld.dataSets.map(ds => {
    return {
      id: ds.id,
      type: ds.type,
      name: ds.name,
      position: ds.position,
      columnIds: ds.columnIds,
    };
  });

  out.columns = dataWorld.columns.map(c => {
    return {
      id: c.id,
      color: c.color,
      liveValue: c.liveValue,
      groupId: c.groupId,
      objectId: c.objectId,
      dataSetId: c.setId, //
      symbol: c.symbol,
      values: c.values,
      updatedRows: c.updatedRows,
      context: c.context,
      dataType: c.dataType,

      allowAutomaticPrecision: c.allowAutomaticPrecision,
      automaticPrecision: c.automaticPrecision,
      scaleMode: c.autoscaleFromZero ? 'autoscale_from_zero' : '', //
      deletable: c.deletable,
      direction: c.direction,
      editable: c.editable,
      name: c.name,
      metered: c.metered,
      meterId: c.meterId,
      plotted: c.plotted,

      precision: {
        automatic: c.precision.automatic,
        precision: c.precision.precision,
        useSciNotation: c.precision.useSciNotation,
        useSigFigs: c.precision.useSigFigs,
      },
      base: c.prefersBase, //
      range: c.range,
      replaceDependent: c.replaceDependent,
      sensorId: c.sensorId,
      sensorMapId: c.sensorMapId,
      SMAXY: c.smaxy, //
      struckRows: c.struckRows,
      type: c.type,
      units: c.units,
      wavelength: c.wavelength,
    };
  });

  out.columnGroups = dataWorld.columnGroups.map(g => {
    return {
      id: g.id,
      automaticPrecision: g.automaticPrecision,
      scaleMode: g.autoscaleFromZero ? 'autoscale_from_zero' : '', //
      calcCoefficients: g.calcCoefficients,
      calcDependentGroups: g.calcDependentGroups,
      calcEquation: g.calcEquation,
      customEq: g.customEq,
      calcUserEditable: g.calcUserEditable,
      color: g.color,
      deletable: g.deletable,
      editable: g.editable,
      metered: g.metered,
      meterId: g.meterId,
      name: g.name,
      plotted: g.plotted,

      precision: {
        automatic: g.precision.automatic,
        precision: g.precision.precision,
        useSciNotation: g.precision.useSciNotation,
        useSigFigs: g.precision.useSigFigs,
      },
      base: g.prefersBase, //
      range: g.range,
      replaceDependent: g.replaceDependent,
      SMAXY: g.smaxy, //
      sensorId: g.sensorId,
      sensorMapId: g.sensorMapId,
      type: g.type,
      units: g.units,
      wavelength: g.wavelength,

      direction: g.direction,
    };
  });

  out._isCollecting = dataWorld._isCollecting;
  out._userConstants = dataWorld._userConstants;
  out.ignoreNameChanges = dataWorld.ignoreNameChanges;
  out.timeUnits = dataWorld.timeUnits;

  out.columnGroupIndices = dataWorld.columnGroupIndices;

  out._graphInfos = dataWorld._graphInfos;
  out._graphIdMap = dataWorld._graphIdMap;
  out.sessionConfig = dataWorld.sessionConfig;
  out.experimentId = dataWorld.experimentId;
  out.isTriggeringEnabled = dataWorld.isTriggeringEnabled;

  return out;
};

export const importDataWorld = async (dataWorld, state) => {
  dataWorld.blockInfoSyncing = true;

  dataWorld._overrideExperimentId = state.experimentId;

  dataWorld.columnGroupIndices = state.columnGroupIndices;
  dataWorld._isCollecting = state._isCollecting;
  dataWorld._userConstants = state._userConstants;
  dataWorld.ignoreNameChanges = state.ignoreNameChanges;
  dataWorld.timeUnits = state.timeUnits;

  dataWorld._graphInfos = state._graphInfos;
  dataWorld._graphIdMap = state._graphIdMap;
  dataWorld.sessionConfig = state.sessionConfig;
  dataWorld.isTriggeringEnabled = state.isTriggeringEnabled;

  state.dataSets.forEach(async dataSet => {
    eventHandlers['dw:data-set-added'].call(dataWorld, dataSet);
    await nextTick(1);
    eventHandlers['dw:data-set-ready'].call(dataWorld, dataSet);
    await nextTick(1);
  });

  state.columnGroups.forEach(async group => {
    eventHandlers['dw:data-group-added'].call(dataWorld, group);
    await nextTick(1);
    eventHandlers['dw:data-group-properties-changed'].call(dataWorld, group);
    await nextTick(1);
  });

  state.columns.forEach(async column => {
    eventHandlers['dw:data-column-added'].call(dataWorld, column);
    const c = dataWorld.getColumnById(column.id);
    console.assert(c);
    c.values = column.values;
    if (column.color) c.color = column.color;
  });

  dataWorld._graphInfos = state._graphInfos;
  dataWorld.sessionConfig = state.sessionConfig;
  dataWorld.isTriggeringEnabled = state.isTriggeringEnabled;

  await nextTick(10); // allows events to be processed
  dataWorld._blockSynced = true;
  delete dataWorld.blockInfoSyncing;
};
