let needWakeLock = false;
let wakeLock = null;

async function acquireWakeLock() {
  if (wakeLock == null || wakeLock.released) {
    try {
      wakeLock = await navigator.wakeLock.request();
      wakeLock.addEventListener('release', () => {
        wakeLock = null;
      });
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  }
}

function handleVisibilityChange() {
  if (needWakeLock && document.visibilityState === 'visible') {
    acquireWakeLock();
  }
}

export async function requestWakeLock() {
  needWakeLock = true;
  await acquireWakeLock();
  document.addEventListener('visibilitychange', handleVisibilityChange);
}

export async function releaseWakeLock() {
  needWakeLock = false;

  if (wakeLock) {
    if (!wakeLock.released) {
      try {
        await wakeLock.release();
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    }
    wakeLock = null;
  }

  document.removeEventListener('visibilitychange', handleVisibilityChange);
}
