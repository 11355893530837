/* eslint-disable no-prototype-builtins */
import { cloneDeep } from 'lodash-es';

export const batchUpdateSensorProperties = (props, sensor, sensorWorld) => {
  // Update any properties that have changed
  Object.keys(props).forEach(key => {
    const oldValue = sensor[key];
    const newValue = props[key];
    if (newValue !== oldValue) {
      sensor[key] = newValue;
    }
  });

  // If emit events for the couple of properties we are expected to
  if (props.units) {
    sensorWorld.emit('sensor-units-changed', sensor);
  }

  if (props.dataMode) {
    // Currently spec specific
    sensorWorld.emit('data-mode-changed', sensor);
  }
};

export const eventHandlers = {
  'device-connection-changed': function deviceConnectionChange(params) {
    this.deviceConnectionChange(params);
  },

  // A Sensor was added to the system
  'sensor-added': function sensorAdded(params) {
    if (
      params.hasOwnProperty('uniqueId') &&
      params.hasOwnProperty('key') &&
      params.hasOwnProperty('autoId') &&
      params.hasOwnProperty('name') &&
      params.hasOwnProperty('units') &&
      params.hasOwnProperty('calibration') &&
      params.hasOwnProperty('sensorInfo') &&
      params.hasOwnProperty('zeroOnCollect') &&
      params.hasOwnProperty('canZeroOnCollect') &&
      params.hasOwnProperty('hasX4Mode') &&
      params.hasOwnProperty('x4Mode') &&
      params.hasOwnProperty('experimentId') &&
      params.hasOwnProperty('reversed')
    ) {
      const { uniqueId } = params;
      const { autoId } = params;
      const sensorId = params.key;
      const { units } = params;
      const { name } = params;
      const { sensorInfo } = params;
      const { zeroOnCollect } = params;
      const { canZeroOnCollect } = params;
      const { hasX4Mode } = params;
      const { x4Mode } = params;
      const { experimentId } = params;
      const { reversed } = params;
      const { wavelength } = params;

      const sensor = this.createInternalSensor({
        id: uniqueId,
        sensorId,
        name,
        units,
        autoId,
        unitInfo: sensorInfo.units,
        dataMode: params.dataMode || null,
        sensorInfo,
        zeroOnCollect,
        canZeroOnCollect,
        hasX4Mode,
        x4Mode,
        experimentId,
        reversed,
        wavelength,
      });

      sensor.updateInternalCalibrationCoeffs(params.calibration.coeffs);

      this.emit('have-sensors', true);
    } else {
      console.error('sensor-added: missing parameters');
    }

    // Suppress sensor name for SpectroVis sensors
    if (params.name === 'SpectroVis') {
      const sensor = this.getSensorById(params.uniqueId);
      sensor._name = '';
    }
  },

  'sensor-ready': function sensorReady(params) {
    if (params.hasOwnProperty('uniqueId') && params.hasOwnProperty('name')) {
      this.emit('sensor-ready', {
        sensorId: params.uniqueId,
        name: params.name,
      });
    }
  },

  // A Sensor udm id changed, fix up our sensor array
  // other properties of the sensor might have also changed, so
  // update everthing we get.
  'sensor-id-changed': function sensorIdChanged(params) {
    if (params.hasOwnProperty('uniqueId') && params.hasOwnProperty('oldId')) {
      const { uniqueId } = params;
      const { oldId } = params;

      const sensor = this.getSensorById(oldId);

      if (sensor) {
        const props = { ...params };
        delete props.uniqueId;
        delete props.oldId;
        delete props.key;

        const newSensor = cloneDeep(sensor);
        newSensor.id = uniqueId;

        this.removeInternalSensor(params.oldId, true); // suppress events
        this.createInternalSensor(newSensor, true); // suppress events

        batchUpdateSensorProperties(props, newSensor, this);
      } else {
        console.warn(`No sensor with id ${oldId} in collection`);
      }
      // console.log(`Sensor id changed: ${oldId} -> ${uniqueId}`);
    }
  },

  // A Sensor was removed from the system
  'sensor-removed': function sensorRemoved(params) {
    if (params.hasOwnProperty('uniqueId')) {
      this.removeInternalSensor(params.uniqueId);
      const { haveSensors } = params;
      if (!haveSensors) {
        this.emit('have-sensors', false);
      }
    }
  },

  // A Sensor went online
  'sensor-online': function sensorOnline(params) {
    if (params.hasOwnProperty('uniqueId')) {
      const { uniqueId } = params;
      const sensor = this.getSensorById(uniqueId);

      // console.log(`Sensor Online Method Called: ${sensor}`);
      // console.dir(params);

      if (sensor) {
        if (params.hasOwnProperty('deviceId')) {
          sensor.deviceId = params.deviceId;
        }

        if (params.hasOwnProperty('channelId')) {
          sensor.channelId = params.channelId;
        }

        if (params.hasOwnProperty('calibrationProcesses')) {
          sensor.updateCalibrationProcesses(params.calibrationProcesses);
        }

        this.emit('sensor-online', sensor);
      }
    }
  },

  // A Sensor went offline
  'sensor-offline': function sensorOffline(params) {
    if (params.hasOwnProperty('uniqueId')) {
      this._setSensorOffline(params.uniqueId);
    }
  },

  // A Sensor's live value readout is available
  'sensor-live-voltage': function sensorLiveVoltage(params) {
    if (params.hasOwnProperty('uniqueId')) {
      const { uniqueId } = params;
      const { value } = params;
      const sensor = this.getSensorById(uniqueId);

      if (sensor) {
        sensor.liveVoltage = value;
      }
    }
  },

  // A Sensor's live spectrum is available
  'sensor-live-spectrum': function sensorLiveSpectrum(params) {
    if (params.hasOwnProperty('uniqueId')) {
      const { uniqueId } = params;
      const { spectrum } = params;
      const sensor = this.getSensorById(uniqueId);

      if (sensor) {
        sensor.liveSpectrum = spectrum;
      }
    }
  },

  // A Sensor's measurement data is ready
  'sensor-data': function sensorData(params) {
    if (
      params.hasOwnProperty('uniqueId') &&
      params.hasOwnProperty('measurements') &&
      params.hasOwnProperty('timestamps') &&
      params.hasOwnProperty('count')
    ) {
      const { count, uniqueId } = params;
      const measurements = new Float64Array(params.measurements, 0, count);
      const timestamps = new Float64Array(params.timestamps, 0, count);

      const sensor = this.getSensorById(uniqueId);
      if (sensor) {
        this.dataCollection.updateSensorData(sensor, measurements, timestamps);
      }
    }
  },

  'sensor-property-change': function sensorPropertyChange(params) {
    if (
      params.hasOwnProperty('uniqueId') &&
      params.hasOwnProperty('key') &&
      params.hasOwnProperty('name') &&
      params.hasOwnProperty('units') &&
      params.hasOwnProperty('autoId') &&
      params.hasOwnProperty('zeroOnCollect') &&
      params.hasOwnProperty('canZeroOnCollect') &&
      params.hasOwnProperty('hasX4Mode') &&
      params.hasOwnProperty('x4Mode')
    ) {
      const sensor = this.getSensorById(params.uniqueId);

      if (sensor) {
        const props = { ...params };

        props.sensorId = props.key;
        delete props.key;

        batchUpdateSensorProperties(props, sensor, this);
      } else {
        console.warn(`Unable to get sensor for id= ${params.uniqueId}`);
      }
    }
  },

  'sensor-calibration-changed': function sensorCalibrationChanged(params) {
    if (params.hasOwnProperty('uniqueId') && params.hasOwnProperty('calibration')) {
      const sensor = this.getSensorById(params.uniqueId);
      if (sensor) {
        sensor.updateInternalCalibrationCoeffs(params.calibration.coeffs);
      } else {
        console.warn(`Unable to get sensor for id= ${params.uniqueId}`);
      }
    }
  },
};
