import EventEmitter from 'eventemitter3';

/**
 * Automation is a service that provides central point for automation specific
 * configuration of various components to facilitate automated testing.
 */
export class Automation extends EventEmitter {
  constructor() {
    super();
    this._config = {};
  }

  _notifyConfigChanged() {
    this.emit('config-changed', this._config);
  }

  get config() {
    return this._config;
  }

  /**
   * Sets graph match points of Automation config.
   * @param {Array} points that describe match position
   */
  set graphMatchPoints(points) {
    this._config.graphMatchPoints = points;
    this._notifyConfigChanged();
  }

  /**
   * @returns {Array} of graph match points of the Automation config.
   * The array might be empty.
   */
  get graphMatchPoints() {
    return this._config.graphMatchPoints || [];
  }
}
