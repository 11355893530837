import { modeLayoutManager } from '@services/datacollection/modeLayoutManager.js';

export class DataCollectionSession {
  constructor({ dataWorld, udm }) {
    this.dataWorld = dataWorld;
    this.sensorWorld = dataWorld.sensorWorld;
    this.dataCollection = dataWorld.dataCollection;
    this.udm = udm;

    this.restoredSession = false;
  }

  // options.imported = true (set if loading a saved session)
  /**
   * start up the session
   * @param {import('@services/dataworld/DataWorld.js').SessionConfig} options
   * @returns {Promise<Boolean|Error>}
   */
  async start(options) {
    const { dataWorld, sensorWorld, dataCollection, udm } = this;
    const services = {
      dataWorld,
      sensorWorld,
      dataCollection,
      udm,
    };

    options.experimentId = this.experimentId;

    try {
      if (options?.imported) {
        await this.dataCollection.sessionRestoring(options);
        this.restoredSession = true;
        await modeLayoutManager.init(options, services);
        await dataWorld.resetDocumentAge();
        return true;
      }
      modeLayoutManager.init(options, services);
      return this.dataCollection.sessionStarting(options);
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async stop() {
    try {
      await this.stopCollection(true);
      await this.dataCollection.sessionClosing();
      modeLayoutManager.uninit();
    } catch (error) {
      console.error(error);
    }
  }

  async startCollection({ appendToDataSet } = {}) {
    const { dataWorld, dataCollection } = this;

    const skipCreateNewRun =
      (dataWorld.currentDataSet && dataWorld.isCurrentDataSetEmpty(true)) || appendToDataSet;

    if (dataWorld.isCollecting) throw new Error('Collection is already running');

    if (!skipCreateNewRun) await dataWorld.createNewDataSet();
    dataWorld.notifyCollectionPreparing();
    await dataCollection.startMeasurements();
  }

  stopCollection(force) {
    const { dataWorld, dataCollection } = this;

    return new Promise((resolve, reject) => {
      if (dataWorld.isCollecting) {
        dataCollection.stopMeasurements(force).then(() => {
          dataWorld._notifyCollectionStopped();
          resolve();
        }, reject);
      } else {
        resolve();
      }
    });
  }

  setupSessionFile() {
    const { dataCollection } = this;
    const saveAsModeName = dataCollection.mode;
    const collectionParams = dataCollection.paramsLookup[saveAsModeName] || {};
    collectionParams.continuous = true;

    dataCollection.setCollectionParams(saveAsModeName, collectionParams);
  }
}
