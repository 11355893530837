import { cloneDeep } from 'lodash-es';
import { BluetoothDevice } from '@services/devicemanager/BluetoothDevice.js';
import { UsbDevice } from '@services/devicemanager/UsbDevice.js';
import { unixEpochStrToDate } from '@utils/helpers.js';

const priv = Symbol('priv');

const getDeviceParams = params => ({
  id: params.id,
  name: params.deviceName,
  connectable: params.connectable,
  rssi: params.rssi,
  state: params.state,

  deviceType: params.type,

  channels: params.channels,
  displayName: params.productName,
  firmware: params.firmware,
  gdxMapVersion: params.gdxMapVersion,

  offlineLoggingStatus: params.offlineLoggingStatus,
  orderCode: params.orderCode,
  serialNumber: params.serialNumber,
  address: params.address,
  canIdentify: params.canIdentify,
  battery: params.battery,
  spectraTimeScale: params.spectraTimeScale,
  spectraTransportTime: params.spectraTransportTime,
  minCollectionDelta: params.minCollectionDelta,
  maxCollectionDelta: params.maxCollectionDelta,
  minWavelength: params.minWavelength,
  maxWavelength: params.maxWavelength,
  customRangeMin: params.customRangeMin,
  customRangeMax: params.customRangeMax,
  minWavelengthSmoothing: params.minWavelengthSmoothing,
  maxWavelengthSmoothing: params.maxWavelengthSmoothing,
  wavelengthSmoothing: params.wavelengthSmoothing,
  minTemporalAveraging: params.minTemporalAveraging,
  maxTemporalAveraging: params.maxTemporalAveraging,
  temporalAveraging: params.temporalAveraging,
  initialWarmupTime: params.initialWarmupTime,
  recalibrationTime: params.recalibrationTime,
  noSkipTime: params.noSkipTime,
  calibrated: params.calibrated,
  integrationTime: params.integrationTime,
  spectrumMode: params.spectrumMode,
  supportedSpectrumModes: params.supportedSpectrumModes,
  supportedLedWavelengths: params.supportedLEDWavelengths,
  ledWavelength: params.LEDWavelength,
  ledIntensity: params.LEDIntensity,
  ledCustomWavelengthSupport: params.LEDCustomWavelengthSupport,
  calibrationProcesses: params.calibrationProcesses,
  manufactureDate: unixEpochStrToDate(params.manufactureDate),
  userCalibrationDate: unixEpochStrToDate(params.userCalibrationDate),
  factoryCalibrationDate: unixEpochStrToDate(params.factoryCalibrationDate),
  hasUserCalibration: params.hasUserCalibration,
  usingUserCalibration: params.usingUserCalibration,
  hasFactoryCalibration: params.hasFactoryCalibration,
  usingFactoryCalibration: params.usingFactoryCalibration,
  hasDeviceAttributes: params.hasDeviceAttributes,
  deviceAttributes: params.deviceAttributes,
  supportsFiniteCollections: params.supportsFiniteCollections,
  numberOfLoggedRuns: params.numberOfLoggedRuns,
  gcProfileRanges: params.gcProfileRanges,
  gcDefaultProfile: params.gcDefaultProfile,
  cvDefaultProfile: params.cvDefaultProfile,
  beDefaultProfile: params.beDefaultProfile,
  ocpDefaultProfile: params.ocpDefaultProfile,
  cvsProfileRanges: params.cvsProfileRanges,
  cvsElectrodeType: params.cvsElectrodeType,
  hasUserConfigurableChannels: params.hasUserConfigurableChannels,
  lotCode: params.lotCode,
  isLegacy: params.isLegacy,
});

/**
 * Check the given device params for an update to offlineStatus
 *
 * @param {object} previousDeviceParams Previous device params
 * @param {object} currentDeviceParams Current device params
 *
 * @return {boolean} True when offlineStatus has changed
 */
function checkDeviceUpdateForOfflineStatusUpdate(previousDeviceParams, currentDeviceParams) {
  const { offlineLoggingStatus: prevOfflineLoggingStatus } = previousDeviceParams;
  const { offlineLoggingStatus: currOfflineLoggingStatus } = currentDeviceParams;

  return (
    currOfflineLoggingStatus &&
    prevOfflineLoggingStatus &&
    currOfflineLoggingStatus.offlineStatus !== prevOfflineLoggingStatus.offlineStatus
  );
}

// events: (see DeviceManager.js)

const eventHandlers = {
  'devmgr:bluetooth-available': function bluetoothAvailable(params) {
    const { available } = params;

    this[priv].bluetoothAvailable = available;
    if (available) {
      this.emit('bluetooth-available');
    } else {
      this.emit('bluetooth-unavailable');
    }
  },

  'devmgr:bluetooth-device-discovered': function bluetoothDeviceDiscovered(params) {
    // console.log(
    //   '-- Bluetooth Device Discovered: id=%d, name=%s, address=%s--',
    //   params.id,
    //   params.deviceName,
    //   params.address,
    // );
    // console.dir(params);
    // console.log('--------------------');

    const initialDeviceList = cloneDeep(this.combinedDeviceList);

    const bluetoothDevice = new BluetoothDevice(getDeviceParams(params));

    this[priv].bluetoothDeviceList.push(bluetoothDevice);

    // fire device-list-related events
    const beforeAfterLists = {
      to: this.combinedDeviceList,
      from: initialDeviceList,
    };
    this._notifyDeviceListChanged(beforeAfterLists, (...args) => this.emit(...args));
    this.emit('bluetooth-device-list-changed', this.bluetoothDeviceList);
  },

  'devmgr:bluetooth-device-removed': function bluetoothDeviceRemoved(params) {
    const initialDeviceList = cloneDeep(this.combinedDeviceList);

    const { bluetoothDeviceList } = this[priv];
    const indexOfDevice = bluetoothDeviceList.findIndex(bd => bd.id === params.id);

    if (indexOfDevice !== -1) {
      const removedDevice = bluetoothDeviceList[indexOfDevice];
      bluetoothDeviceList.splice(indexOfDevice, 1);

      removedDevice.off();

      // fire device-list-related events
      const beforeAfterLists = {
        to: this.combinedDeviceList,
        from: initialDeviceList,
      };
      this._notifyDeviceListChanged(beforeAfterLists, (...args) => this.emit(...args));
      this.emit('bluetooth-device-list-changed', this.bluetoothDeviceList);
    } else {
      console.warn(`No bluetooth device found with id ${params.id}`);
    }
  },

  'devmgr:bluetooth-device-updated': function bluetoothDeviceUpdated(params) {
    const initialDeviceList = cloneDeep(this.combinedDeviceList);

    const { bluetoothDeviceList } = this[priv];
    const indexOfDevice = bluetoothDeviceList.findIndex(bd => bd.id === params.id);

    if (indexOfDevice !== -1) {
      const device = bluetoothDeviceList[indexOfDevice];
      const deviceUpdate = getDeviceParams(params);
      const hasOfflineStatusUpdate = checkDeviceUpdateForOfflineStatusUpdate(device, deviceUpdate);

      device._update(deviceUpdate);

      // fire device-list-related events
      const beforeAfterLists = {
        to: this.combinedDeviceList,
        from: initialDeviceList,
      };
      this._notifyDeviceListChanged(beforeAfterLists, (...args) => this.emit(...args));
      this.emit('bluetooth-device-list-changed', this.bluetoothDeviceList);
      if (hasOfflineStatusUpdate) {
        this.emit('device-offline-status-changed', deviceUpdate.offlineLoggingStatus.offlineStatus);
      }
    } else {
      console.warn(`No bluetooth device found with id ${params.id}`);
    }
  },

  // usb device attached and added to the system
  'devmgr:usb-device-added': function usbDeviceAdded(params) {
    const initialDeviceList = cloneDeep(this.combinedDeviceList);

    const usbDevice = new UsbDevice(getDeviceParams(params));

    const list = this[priv].usbDeviceList;
    if (!list.find(dev => params.id === dev.id)) {
      list.push(usbDevice);
    } else {
      console.warn(`USB Device (id=${params.id}): already in DeviceManager`);
    }

    // fire device-list-related events
    const beforeAfterLists = {
      to: this.combinedDeviceList,
      from: initialDeviceList,
    };
    this._notifyDeviceListChanged(beforeAfterLists, (...args) => this.emit(...args));
  },

  'devmgr:usb-device-removed': function usbDeviceRemoved(params) {
    // usb device attached and added to the system

    const initialDeviceList = cloneDeep(this.combinedDeviceList);

    const { usbDeviceList } = this[priv];
    const indexOfDevice = usbDeviceList.findIndex(dev => dev.id === params.id);

    if (indexOfDevice !== -1) {
      const removedDevice = usbDeviceList[indexOfDevice];
      usbDeviceList.splice(indexOfDevice, 1);

      removedDevice.off();

      // fire device-list-related events
      const beforeAfterLists = {
        to: this.combinedDeviceList,
        from: initialDeviceList,
      };
      this._notifyDeviceListChanged(beforeAfterLists, (...args) => this.emit(...args));
    } else {
      console.warn(`No USB device found with id ${params.id}`);
    }
  },

  'devmgr:usb-device-updated': function usbDeviceUpdated(params) {
    const initialDeviceList = cloneDeep(this.combinedDeviceList);

    const { usbDeviceList } = this[priv];
    const usbDevice = usbDeviceList.find(dev => dev.id === params.id);

    if (usbDevice) {
      const deviceUpdate = getDeviceParams(params);
      const hasOfflineStatusUpdate = checkDeviceUpdateForOfflineStatusUpdate(
        usbDevice,
        deviceUpdate,
      );

      usbDevice._update(deviceUpdate);

      // fire device-list-related events
      const beforeAfterLists = {
        to: this.combinedDeviceList,
        from: initialDeviceList,
      };
      this._notifyDeviceListChanged(beforeAfterLists, (...args) => this.emit(...args));
      if (hasOfflineStatusUpdate) {
        this.emit('device-offline-status-changed', deviceUpdate.offlineLoggingStatus.offlineStatus);
      }
    } else {
      console.warn(`No USB device found with id ${params.id}`);
    }
  },

  'devmgr:calibration-step-result': function calibrationStepResult(params) {
    const deviceList = this[priv].usbDeviceList.concat(this[priv].bluetoothDeviceList);
    const device = deviceList.find(dev => dev.id === params.id);
    if (device) {
      device.updateCalibrationStep({
        result: params.result,
      });
    } else {
      console.warn(`No device found with id ${params.id}`);
    }
  },

  'devmgr:device-attributes-changed': function deviceAttributesChanged(params) {
    const initialDeviceList = cloneDeep(this.combinedDeviceList);
    const device = initialDeviceList.find(dev => dev.id === params.deviceId);

    if (device) {
      device._update({
        deviceAttributes: params.deviceAttributes,
      });
    }
  },
};

export const handlerData = {
  eventHandlers,
  priv,
};
