import { modeLayoutManager } from '@services/datacollection/modeLayoutManager.js';
import { nextTick } from '@utils/nextTick.js';

export class ManualSession {
  constructor({ dataWorld, udm }) {
    this.dataWorld = dataWorld;
    this.sensorWorld = dataWorld.sensorWorld;
    this.dataCollection = dataWorld.dataCollection;
    this.udm = udm;
    this.restoredSession = false;
  }

  // opts.restore = true (set if loading a restored session)
  async start(opts) {
    const { dataWorld, sensorWorld, dataCollection, udm } = this;
    const services = {
      dataWorld,
      sensorWorld,
      dataCollection,
      udm,
    };

    try {
      if (!opts?.restored && !opts?.imported) {
        await this.dataCollection.sessionStarting(opts);
        modeLayoutManager.init(opts, services);
      } else {
        /* imported sessions */
        await this.dataCollection.sessionRestoring(opts);
        this.restoredSession = true;
        await modeLayoutManager.init(opts, services);

        // Allow various async handlers fired by the layout manager and graph components to settle
        // (the await above should cover this, but does not). MEG-1597
        await nextTick(300);
        await dataWorld.resetDocumentAge();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async stop() {
    const { dataCollection } = this;
    try {
      await dataCollection.sessionClosing();
      modeLayoutManager.uninit();
    } catch (error) {
      console.error(error);
    }
  }
}
