import EventEmitter from 'eventemitter3';
import { vstAuthStore } from '@stores/vst-auth.store.js';
import { windowLocationReplace } from '@utils/window.js';
import * as platform from './PLATFORM_ID';
import {
  sessionId,
  groupId,
  experimentId,
  hostId,
  blockId,
  searchParams,
  chromeManagedConfigurationHandled,
  hashParams,
} from './id-utils.js';

class StartupUrlHandler extends EventEmitter {
  constructor() {
    super();
    this._search = searchParams;
    this._hashParams = hashParams;
    this._messageListener = this.__messageListener.bind(this);

    /**
     * @type {null|Boolean} ensures that listeners are only ever setup in place once, cancelling any possible subsequent calls to initialize listeners,
     * unless they are removed first. The value will only be null prior to first initialization and it set this way for possible debugging of an uncalled
     * initialization.
     */
    this._setup = null;
  }

  // eslint-disable-next-line class-methods-use-this
  get sessionId() {
    return sessionId();
  }

  // eslint-disable-next-line class-methods-use-this
  get groupId() {
    return groupId();
  }

  get groupComplete() {
    return this._hashParams.get('groupComplete') === 'true';
  }

  get bypassRequiredDevices() {
    return this._hashParams.has('bypassRequiredDevices');
  }

  // eslint-disable-next-line class-methods-use-this
  get experimentId() {
    return experimentId();
  }

  // eslint-disable-next-line class-methods-use-this
  get hostId() {
    return hostId();
  }

  // eslint-disable-next-line class-methods-use-this
  get blockId() {
    return blockId();
  }

  get blockType() {
    return this._search.get('blockType');
  }

  // checks if we are a block based on URL parameters
  get isBlock() {
    return !!(this.sessionId || this.experimentId || this.blockId);
  }

  get isDemoMode() {
    return this._search.has('demo-mode');
  }

  get manual() {
    return this._search.has('manual');
  }

  get isStudentSession() {
    return this.sessionId && !this.isDemoMode;
  }

  get isContentCreatorSession() {
    return this.experimentId && !this.sessionId && !this.isDemoMode;
  }

  get _hasSession() {
    return this.__hasSession;
  }

  set _hasSession(value) {
    this.__hasSession = value;
    this.emit('has-session-updated', value);
  }

  get hasSession() {
    return this._hasSession !== undefined
      ? Promise.resolve(this._hasSession)
      : new Promise(resolve => {
          this.once('has-session-updated', hasSession => {
            resolve(hasSession);
          });
        });
  }

  get hasFile() {
    if (
      this._search.has('video') ||
      this._search.has('experimenturl') ||
      this._search.has('experimentid') ||
      this.sessionId ||
      this.experimentId
    )
      return true;
    return false;
  }

  static async authorizeApp(key) {
    try {
      await vstAuthStore.authorize(key);
    } catch (error) {
      if (error.message === 'eula') {
        const eulaUrl = window.location.hostname.includes('graphicalanalysis')
          ? '/accept/index.html'
          : '/auth/accept.html';
        windowLocationReplace(`${eulaUrl}${key ? `?key=${key}` : ''}`);
        return;
      }
      windowLocationReplace(`/auth/${key ? `?key=${key}` : ''}`);
      return;
    }

    const url = new URL(window.location.href);

    window.history.replaceState(null, null, url.toString());
  }

  async handleVideo(videoLocation) {
    if (await this.hasSession) return;
    const response = await fetch(videoLocation.replace('sample-videos/', 'sample-videos%2F'));
    if (!response.ok) {
      this.emit('startup-url-error', {
        status: response.status,
        fileType: 'video',
        location: videoLocation,
      });
      return;
    }
    const blob = await response.blob();
    const namePath = videoLocation.split('/').pop();
    const videoFile = new File([blob], `${namePath}`, { type: blob.type });
    this.emit('startup-url-video', {
      videoData: {
        file: videoFile,
        filepath: namePath,
      },
    });
  }

  async handleExperimentUrl(experimentLocation) {
    if (await this.hasSession) return;
    let response;
    try {
      response = await fetch(
        // this is a unique step for the firebase url, we should either conditionalize it or get rid of it, unless we are hosting some ourselves
        experimentLocation.replace('sample-experiments/', 'sample-experiments%2F'),
        { mode: 'cors' },
      ).catch(error => {
        return { ok: false, status: error.message };
      });
    } catch {
      response = { ok: false };
    }
    if (!response.ok) {
      this.emit('startup-url-error', {
        status: response.status,
        fileType: 'experiment',
        location: experimentLocation,
        skipToast: !this.fallbackExperiment || this.manual,
      });
      return;
    }
    const blob = await response.blob();
    const fileName = experimentLocation.split('/').slice(-1)[0];
    const file = new File([blob], fileName, { type: blob.type });
    this.emit('startup-url-experiment', {
      fileData: { file, filepath: file.name, name: file.name },
    });
  }

  /**
   * Handles the presence of a session ID by fetching the corresponding session file from the server.
   *
   * @param {string} sessionId - The ID of the session.
   * @param {Object} [options] - Optional parameters.
   * @param {boolean} [options.exludeAnalysis] - Indicates to exclude analysis from the file load.
   * @param {boolean} [options.skipWatcher] - Indicates to skip the startup-url-session event.
   * @returns {Promise<boolean>} - A promise that resolves to true if the session file is successfully fetched, false otherwise.
   */
  async handleSessionId(sessionId, options = {}) {
    await navigator.serviceWorker.ready;
    let response;
    let testResponseText = '';
    try {
      response = await fetch(`${SESSION_API_URL}/${sessionId}`, { mode: 'cors' }).catch(error => {
        return { ok: false, status: error.message, error };
      });
      if (response.error) throw new Error(response.error);
      const testResponse = response.clone();
      testResponseText = await testResponse.text();
    } catch {
      response = { ok: false };
    }
    if (!response.ok || testResponseText.includes('<Code>AccessDenied</Code>')) {
      this.removeMessageListener();
      this.fallbackExperiment = true;
      return false;
    }
    const sessionFile = await response.blob();
    if (sessionFile) {
      const file = new File([sessionFile], sessionId, { type: sessionFile.type });
      this.emit('startup-url-experiment', {
        fileData: {
          file,
          filepath: file.name,
          name: file.name,
        },
        exludeAnalysis: options.exludeAnalysis,
      });
      return true;
    }
    return false;
  }

  async handleGroupId(groupId) {
    return this.handleSessionId(`group/${groupId}`, { exludeAnalysis: true, skipWatcher: true });
  }

  /**
   * Check for a block session and check all startup possibilities
   */
  async _checkBlockStartup() {
    if (!(this.sessionId || this.groupId || this.experimentId)) return;

    this._hasSession = this.sessionId ? await this.handleSessionId(this.sessionId) : false;
    if (this._hasSession) return;

    if (this.groupId && (await this.handleGroupId(this.groupId))) return;

    if (this.experimentId) {
      if (!window.__isSessionClient || this.fallbackExperiment)
        this.handleExperimentUrl(`${EXPERIMENT_API_URL}/${this.experimentId}`);
    }
  }

  /**
   * Check opening URL
   *
   * @param {function} validateJWT Function for validating
   * @param {object} opts Options
   * @param {boolean} opts.requireKey Whether a key is required, redirecting if not present   *
   */
  async checkOpeningUrl(validateJWT, { requireKey = true } = {}) {
    await this._checkBlockStartup();
    if (this._search.has('video')) this.handleVideo(this._search.get('video'));
    if (this._search.has('experimenturl'))
      this.handleExperimentUrl(this._search.get('experimenturl'));

    await chromeManagedConfigurationHandled;
    if (this._search.has('key')) {
      const key = this._search.get('key');
      await StartupUrlHandler.authorizeApp(key);
    } else if (requireKey && !(await vstAuthStore.validateLicense(validateJWT))) {
      windowLocationReplace('/auth/');
    }
  }

  __messageListener({ data }) {
    // splitting these arrays out to document where the come from

    const cacheNotifications = ['FILE_CACHE_UPDATED', 'FILE_CACHE_USED'];
    const fileSaveNotifications = ['FILE_SAVE_PENDING', 'FILE_SAVE_ERROR', 'FILE_SAVE_SUCCESS'];
    if (![...cacheNotifications, ...fileSaveNotifications].includes(data.type)) return;
    this.emit(data.type, data.payload);
  }

  setupMessageListener() {
    if (!navigator.serviceWorker || this._setup) return;
    navigator.serviceWorker.addEventListener('message', this._messageListener);
    this._setup = true;
  }

  removeMessageListener() {
    if (!navigator.serviceWorker) return;
    navigator.serviceWorker.removeEventListener('message', this._messageListener);
    this._setup = false;
  }
}

export function createUrlHandler() {
  if (platform.createUrlHandler) {
    return platform.createUrlHandler();
  }

  return new StartupUrlHandler();
}
