import EventEmitter from 'eventemitter3';

/**
 * All graph properties
 * @typedef GraphProperties
 * @type {object}
 * @properties {string} type
 * @properties {number} graphId
 * @properties {number} baseColumnId
 * @properties {boolean} visible
 * @properties {boolean} lines
 * @properties {boolean} points
 * @properties {boolean} bars
 * @properties {boolean} interpolate
 * @properties {boolean} rightAxisEnabled
 * @properties {number} xMin
 * @properties {number} xMax
 * @properties {number} yMin
 * @properties {number} yMax
 * @properties {number} rightYMin
 * @properties {number} rightYMax
 * @properties {number} baseScalingMode
 * @properties {string} leftScalingMode
 * @properties {string} rightScalingMode
 * @properties {string} title
 * @properties {object} fftIndo
 * @properties {array} auxGraphIds
 * @properties {number} currentGraphAuxId
 */

/**
 * Graph trace info
 * @typedef {Object} TraceInfo
 * @param {number} baseColumnId
 * @param {number} traceColumnId
 * @param {boolean} isRightAxisTrace
 */

export class DataWorldAPI extends EventEmitter {
  /**
   * @type {number} time duration in ms for RPC completion above which we will
   * issue `rpc-time-warning` notifications. Note: a standard animation frame
   * tick requires no more than 17ms total time, so we're being generous here.
   */
  TIME_WARNING_THRESHOLD_MS = 80;

  constructor(rpc) {
    super();
    this.rpc = rpc;

    const RPC_METHODS = {};

    // Server Notifications
    [
      'dw:data-set-added',
      'dw:data-set-ready',
      'dw:data-set-removed',
      'dw:data-column-added',
      'dw:data-column-removed',
      'dw:data-column-updated',
      'dw:data-set-updated',
      'dw:unit-change-finished',
      'dw:data-set-row-added',
      'dw:data-column-values-updated',
      'dw:data-column-live-readout-changed',
      'dw:data-graph-updated',
      'dw:data-annotation-updated',
      'dw:data-video-updated',
      'dw:data-group-added',
      'dw:data-group-removed',
      'dw:data-group-properties-changed',
      'dw:session-started',
      'dw:document-age-updated',
      'gc-collection-started',
      'collection-started',
      'collection-ended',
      'complete-collection',
      'trigger-reached',
      'prestore-reached',
      'replay-engine-update',
      'dw:strikethrough-changed',
      'dw:video-trace-object-color-changed',
    ].forEach(id => {
      RPC_METHODS[id] = (server, notification) => {
        const { params } = notification;
        const start = Date.now();
        this.emit(id, params);
        // If the RPC handler took longer than the threshold, we will emit a
        // warning notification.
        const duration = Date.now() - start;
        if (duration > this.TIME_WARNING_THRESHOLD_MS) {
          this.emit('rpc-time-warning', {
            rpcId: id,
            duration,
            threshold: this.TIME_WARNING_THRESHOLD_MS,
          });
        }
      };
    });

    this.rpc.registerMethods(RPC_METHODS, this);
  }

  /**
   * Returns success Promise
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise} Promise object represents a success or failure: { success: true|false }
   */
  setSessionReadyForDataCollection(experimentId) {
    return this.rpc.sendRequest('experiment-ready-for-data-collection', { experimentId });
  }

  /**
   * Returns success Promise
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise} Promise object represents a success or failure: { success: true|false }
   */
  getPageAttributes(params = {}) {
    return this.rpc.sendRequest('page-attributes', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {string} name
   */
  setExperimentName(params) {
    return this.rpc.sendRequest('set-experiment-name', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} baseColId
   * @param {number} traceColId
   * @param {number} pixelWidth
   */
  calculateSparkLineForColumn(params) {
    return this.rpc.sendRequest('sparkline-for-column', params);
  }

  /**
   * Returns success Promise
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sourceId
   * @returns {Promise} Promise object represents a success or failure: { success: true|false }
   */
  getDataShareSource(params) {
    return this.rpc.sendRequest('data-share-source', params);
  }

  /**
   * @param {object} params
   * @param {number} experimentId - UDM id of the experiment
   * @param {number=} params.foreignId
   * @param {string=} params.name
   * @param {number=} params.matchGroupId
   * @param {string} params.type
   * @returns {Promise} Promise<{ dataSetId, foreignId }>
   */
  createNewDataSet(params) {
    return this.rpc.sendRequest('create-new-dataset', params);
  }

  /**
   * @param {object} params
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} params.dataSetId
   * @param {number} params.foreignId
   * @param {number} params.foreignGroupId
   * @param {number} params.foreignSetId
   * @param {number} params.previousId
   * @param {string} params.name
   * @param {string} params.units
   * @param {string} params.formatStr
   * @param {boolean} params.editable
   * @returns {Promise} Promise<{ columnId, foreignId }>
   */
  createNewColumn(params) {
    return this.rpc.sendRequest('create-new-column', params);
  }

  /**
   * @param {object}  args
   * @param {number}  args.experimentId - UDM id of the experiment
   * @param {string}  args.name
   * @param {string}  args.unit
   * @param {object}  args.precision
   * @param {number}  args.precision.precision
   * @param {boolean}  args.precision.useSciNotation
   * @param {boolean}  args.precision.useSigFigs
   * @param {boolean}  args.automaticPrecision,
   * @param {number}  args.previousId
   * @param {boolean} args.metered [optional] should columns created by this method display meters?
   * @param {import('@api/common/Column.js').ColumnDataType} args.dataType either 'numeric' or 'text'
   *
   */
  createManualColumnGroup(args) {
    return this.rpc.sendRequest('create-user-manual-column', args);
  }

  /**
   * @param {object}  args
   * @param {number}  args.experimentId - UDM id of the experiment
   * @param {string}  args.name
   * @param {string}  args.unit
   * @param {object}  args.precision
   * @param {number}  args.precision.precision
   * @param {boolean}  args.precision.useSciNotation
   * @param {boolean}  args.precision.useSigFigs
   * @param {boolean}  args.automaticPrecision,
   * @param {number}  args.previousId
   * @param {string} args.calcEquation
   * @param {boolean} args.metered [optional] should columns created by this method display meters?
   * @param {number[]} args.calcDependentGroups - Array of groupIds
   * @param {number[]} args.calcCoefficients - Array of coefficients
   *
   */
  createUserCalcColumnGroup(args) {
    return this.rpc.sendRequest('create-user-calc-column', args);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} groupId
   */
  removeColumnGroup(params) {
    return this.rpc.sendRequest('remove-column-group', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} id
   * @param {object} params
   * @param {string}
   */
  updateDataSetProperties(params) {
    // TODO rename params to properties to be consistent
    return this.rpc.sendRequest('update-dataset-properties', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} groupId
   * @param {object} properties
   * @param {object} properties.precision
   * @param {string} properties.name
   * @param {string} properties.units
   * @param {boolean} properties.metered
   * @param {boolean} properties.base
   * @param {boolean} properties.replaceDependent
   * @param {boolean} properties.plotted
   * @param {boolean} properties.automaticPrecision
   */
  updateColumnGroup(params) {
    return this.rpc.sendRequest('update-column-group', params);
  }

  /**
   * @param {object} params
   * @param {number} params.experimentId - UDM id of the experiment
   * @param {number} params.dataSetId
   * @param {number} params.row
   * @param {number} params.count
   * @param {boolean} params.strikethrough
   */
  strikeRows(params) {
    return this.rpc.sendRequest('strike-rows', params);
  }

  /**
   * @param {object} params
   * @param {number} params.experimentId - UDM id of the experiment
   */
  unstrikeAllRows(params) {
    return this.rpc.sendRequest('unstrike-all-rows', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} id - udmId
   * @param ({string|number)[]} values - array column values
   * @param {boolean} trim
   */
  updateColumnValues(params) {
    return this.rpc.sendRequest('update-column-values', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} id - udmId
   * @param {import('@api/common/Column.js').ColumnDataType} type - 'numeric' or 'text'
   * @returns
   */
  changeColumnDataType(params) {
    return this.rpc.sendRequest('change-column-data-type', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} id - udmId
   * @param {number} value - cell value
   * @param {number[]} readings - array of sensor readings
   */
  addEventData(params) {
    // TODO: backend code needs to be moved from MI_DataCollection to MI_DataWorld
    return this.rpc.sendRequest('add-event-data', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} id - udmId
   * @param {string} unit - column units
   */
  changeColumnUnit(params) {
    return this.rpc.sendRequest('change-column-unit', params);
  }

  /**
   * This method is only for simple changes that happen purely in the UDM model
   * i.e., not name and units
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} id - udm column id
   * @param {object} properties
   * @param {string} properties.color - column color as string
   * @param {string} properties.symbol - point symbol
   */
  changeColumnProperties(params) {
    // make consistent with other rpc api params
    return this.rpc.sendRequest('change-column-properties', params);
  }

  /**
   * Add graph to UDM
   * @param {number} experimentId - UDM id of the experiment
   * @param {GraphProperties} properties - the {@link GraphProperties}
   */
  addGraph(properties) {
    return this.rpc.sendRequest('add-graph', properties);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} graphId
   * @param {GraphProperties} props
   */
  changeGraphProperties(props) {
    const _props = { ...props };

    // The native API uses the 'type' prop to indicate bars rather than the 'bars' prop
    // props must be structured differently for lines/points vs bars, or else other will not save correctly
    if (props.appearance?.bars || props.bars) {
      _props.type = 'bar-chart';
    } else if (
      props?.appearance?.lines ||
      props?.appearance?.points ||
      props?.lines ||
      props?.points
    ) {
      _props.type = 'normal';
    }

    // TODO: Rename change-graph to change-graph-properties
    return this.rpc.sendRequest('change-graph', _props);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} graphId
   * @param {TraceInfo[]} traces
   */
  addGraphTraces(props) {
    return this.rpc.sendRequest('add-graph-traces', props);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} graphId
   * @param {TraceInfo[]} traces
   */
  removeGraphTraces(props) {
    return this.rpc.sendRequest('remove-graph-traces', props);
  }

  /**
   * Adds (or updates existing) graph curvie fit object.
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} graphId
   * @param {number} fitId
   * @param {object} params
   * @param {number} params.baseColumnId
   * @param {number} params.traceColumnId
   * @param {number} params.rmse
   * @param {number} params.correlation
   * @param {number} params.xMin
   * @param {number} params.xMax
   * @param {string} params.fitType - PROPORTIONAL LINEAR QUADRATIC POWER INVERSE INVERSE_SQUARED NATURAL_EXPONENT NATURAL_LOG SINE COSINE COSINE_SQUARED
   * @param {number[]} params.coeffs
   * @param {bool} params.showUncertainty
   * @param {InfoBox} params.infoBox
   * @throws {Error} with failure information
   * @returns {Promise<number>} on completion, udm ID of newly created curve fit object.
   */
  addGraphCurveFit(params) {
    return this.rpc.sendRequest('add-graph-curve-fit', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} fitId - udm fitId
   * @return {Promise} { success, fitId }
   */
  removeGraphCurveFit(params) {
    return this.rpc.sendRequest('remove-graph-curve-fit', params);
  }

  /**
   * Adds (or updates existing) graph integral object.
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} graphId
   * @param {number} integralId
   * @param {object} integralParams
   * @param {number} integralParams.baseColumnId
   * @param {number} integralParams.traceColumnId
   * @param {number} integralParams.xMin
   * @param {number} integralParams.xMax
   * @param {InfoBox} integralParams.infoBox
   * @returns {Promise<number>} on completion, udm ID of newly created integral object.
   */
  addGraphIntegral(params) {
    return this.rpc.sendRequest('add-graph-integral', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} integralId
   */
  removeGraphIntegral(params) {
    return this.rpc.sendRequest('remove-graph-integral', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} graphId
   * @param {number} integralId
   * @param {object} params
   * @param {number} params.baseColumnId
   * @param {number} params.traceColumnId
   * @param {number} params.xMin
   * @param {number} params.xMax
   * @param {number} params.peakNumber
   * @param {number} params.leftmostId
   * @param {number} params.rightmostId
   * Are these necessary to persist? We eventually derive these from the column Ids and min and max.
   * @param {number} params.baselineIntercept
   * @param {number} params.baselineSlop
   *
   */
  addGraphPeakIntegral(params) {
    return this.rpc.sendRequest('add-or-change-graph-peak-integral', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} integralId
   */
  removeGraphPeakIntegral(params) {
    return this.rpc.sendRequest('remove-graph-peak-integral', params);
  }

  /**
   * Adds (or updates existing) statistics object to graph
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} graphId
   * @param {number} statsId
   * @param {object} statsParams
   * @param {number} statsParams.baseColumnId
   * @param {number} statsParams.traceColumnId
   * @param {number} statsParams.xMin
   * @param {number} statsParams.xMax
   * @param {InfoBox} statsParams.infoBox
   * @returns {Promise<number>} on completion, udm ID of newly created stats object.
   */
  addGraphStats(params) {
    return this.rpc.sendRequest('add-graph-stats', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} statsId
   */
  removeGraphStats(params) {
    return this.rpc.sendRequest('remove-graph-stats', params);
  }

  /**
   * Sets info box position and collapsed state.
   * @param {object} params object containing information about the info box we'd like to change.
   * @param {number} params.experimentId - UDM id of the experiment
   * @param {number} params.helperId udm ID of the curve fit, integral, or statistics (et al.) box.
   * @param {boolean} params.showUncertainty [optional] if this info box belongs is a curve fit, show or hide the uncertainty field.
   * @param {InfoBox} params.infoBox info containing position and collapsed state.
   * @returns {Promise} then / await if success.
   * @throws {Error} if operation fails.
   */
  setInfoBoxInfo(params) {
    return this.rpc.sendRequest('set-infoxbox-info', params);
  }

  /**
   * Fetches all raw annotation dictionaries stored in udm for a given
   * experiemnt id.
   * @param {number} experimentId identifier for the experiment.
   * @returns {Promise<import('@common/mobx-stores/Annotation').RawAnnotationData[]>}
   * promise that resolves to an array of objects containing properties suitable
   * for creating new Annotation instances.
   */
  fetchGraphAnnotations(experimentId) {
    return this.rpc.sendRequest('fetch-graph-annotations', { experimentId });
  }

  /**
   * Add a graph annotation.
   * @param {import('@common/mobx-stores/Annotation').RawAnnotationData} params
   * contains properties that one uses to construct an annotation, plus the
   * experimentId.
   */
  addGraphAnnotation(params) {
    return this.rpc.sendRequest('add-graph-annotation', params);
  }

  /**
   * Update changes to an annotation in the udm store.
   * @param {import('@common/mobx-stores/Annotation').RawAnnotationData} params
   */
  updateGraphAnnotation(params) {
    this.rpc.sendRequest('update-graph-annotation', params);
  }

  /**
   * Remove a graph annotaion from UDM.
   * @param {object} params
   * @param {number} params.experimentId - UDM id of the experiment
   * @param {number} params.annotationId
   */
  removeGraphAnnotation(params) {
    return this.rpc.sendRequest('remove-graph-annotation', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} datasetId
   */
  removeDataSet(params) {
    return this.rpc.sendRequest('remove-dataset', params);
  }

  /**
   * Check to see if an android recovery file is available
   * @param {number} experimentId - UDM id of the experiment
   */
  checkRecoveryFile(experimentId) {
    return this.rpc.sendRequest('check-recovery-file', { experimentId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {object} params
   * @param {string} params.name
   * @param {string} params.format - [gasv]mbl, csv
   * @param {string} params.filepath
   * @param {string=} params.decimal - optional decimal, only included if format is csv
   * @param {number=} params.age - optional age
   */
  exportData(params) {
    return this.rpc.sendRequest('export', params);
  }

  /**
   *
   * @param {string} path
   * @param {string} format
   * @param {string} content
   * @param {ExperimentSubset} [subset] - the subset to import, defaults to complete import
   */
  importData(path, format, content, subset = null) {
    return this.rpc.sendRequest('import', { path, format, content, subset });
  }

  /**
   * @deprecated looks like this is unused now?
   * @param {number} experimentId - UDM id of the experiment
   * @param {string} path
   * @param {string} format
   * @param {string} content
   */
  getDocumentMetaData(params) {
    return this.rpc.sendRequest('document-meta-data', params);
  }

  /**
   * Passes the sessionSubtype value to the back end for persistence and in the case of ia, graph trace assignment.
   * @param {number} experimentId - UDM id of the experiment
   * @param {string} subtype
   */
  setSessionSubtype(params) {
    // Also back end.
    return this.rpc.sendRequest('set-experiment-subtype', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {object} state
   * @param {string} state.text optional
   * @param {boolean} state.visible optional
   */
  setNotesState(params) {
    return this.rpc.sendRequest('set-notes-state', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  getNotesState(experimentId) {
    return this.rpc.sendRequest('get-notes-state', { experimentId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {string} name
   * @param {string} expression
   */
  setCustomCurveFit(params) {
    return this.rpc.sendRequest('set-custom-curve-fit', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  getCustomCurveFits(experimentId) {
    return this.rpc.sendRequest('get-custom-curve-fits', { experimentId });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @returns An array of all functions available for custom calc columns.
   */
  getCalcColumnFunctions(experimentId) {
    return this.rpc.sendRequest('get-calc-column-functions', { experimentId });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @returns an array of all user constants.
   */
  getUserConstants(experimentId) {
    return this.rpc.sendRequest('get-user-constants', { experimentId });
  }

  /**
   * @param {number} experimentId UDM id of the experiment
   * @param {ConstantProps} constant
   * @returns {promise<number>} that resolves to UDM id of the constant
   *
   * For any of the optinal properties of ConstantProps not supplied values will be reset to the UDM defaults
   */
  addUserConstant(experimentId, constant) {
    return this.rpc.sendRequest('add-user-constant', { experimentId, ...constant });
  }

  /**
   * @param {number} experimentId UDM id of the experiment
   * @param {number} constantId UDM id of the constant to modify
   * @param {ConstantProps} constant
   * @returns {promise<>}
   *
   * For any of the optinal properties of ConstantProps not supplied values will be reset to the UDM defaults
   */
  changeUserConstant(experimentId, constantId, constant) {
    return this.rpc.sendRequest('change-user-constant', { experimentId, constantId, ...constant });
  }

  /**
   * @param {number} experimentId UDM id of the experiment
   * @param {number} constantId UDM id of the constant to delete
   * @returns {promise<>}
   */
  deleteUserConstant(experimentId, constantId) {
    return this.rpc.sendRequest('delete-user-constant', { experimentId, constantId });
  }

  /**
   * Prepare column bindings by converting string IDs to integers
   * @param {Array} bindings array of binding objects.
   * @returns array of columns bindings with correct integer based ids.
   */
  // eslint-disable-next-line class-methods-use-this
  _prepareAuxBindings(bindings) {
    if (!bindings) return [];
    return bindings.map(cb => {
      return {
        inputBaseId: parseInt(cb.inputBaseId ?? 0),
        inputTraceId: parseInt(cb.inputTraceId ?? 0),
        inputColumn: parseInt(cb.inputColumn ?? 0),
        outputBaseId: parseInt(cb.outputBaseId),
        outputTraceId: parseInt(cb.outputTraceId),
      };
    });
  }

  /**
   * Adds an auxiliary graph to UDM.
   * @param {number} experimentId - UDM id of the experiment
   * @param { String } parentGraphId id of graph that will own or contain the new auxiliary graph.
   * @param { String } auxGraphType describes type of new graph, 'fft' or 'histogram'.
   * @param { Object } auxInfo contains information specific to this new aux graph.
   * @returns
   */
  addAuxGraph(experimentId, _parentGraphId, auxGraphType, auxInfo) {
    const parentGraphId = parseInt(_parentGraphId);
    auxInfo.columnBindings = this._prepareAuxBindings(auxInfo.columnBindings);

    return this.rpc.sendRequest('add-aux-graph', {
      experimentId,
      parentGraphId,
      auxGraphType,
      auxInfo,
    });
  }

  /**
   * Updates the data of an existing auxiliary graph.
   * @param {number} experimentId - UDM id of the experiment
   * @param { String } auxGraphId identifier of the aux graph
   * @param { Object } auxInfo update the auxiliary graph information. Contains information specific to ffts or histograms, depending on the type it was created with.
   * @returns true or false depending on success of operation.
   */
  updateAuxGraphInfo(experimentId, auxGraphId, auxInfo) {
    const _auxGraphId = parseInt(auxGraphId);
    auxInfo.columnBindings = this._prepareAuxBindings(auxInfo.columnBindings);

    return this.rpc.sendRequest('update-aux-graph', {
      experimentId,
      auxGraphId: _auxGraphId,
      auxInfo,
    });
  }

  /**
   * Remove an auxiliary graph from the back end.
   * @param {number} experimentId - UDM id of the experiment
   * @param {*} auxGraphId id of aux graph to be removed.
   */
  deleteAuxGraph(experimentId, auxGraphId) {
    const _auxGraphId = parseInt(auxGraphId);
    return this.rpc.sendRequest('delete-aux-graph', { experimentId, auxGraphId: _auxGraphId });
  }

  /**
   * Sets block info. This is how VSW will initially persist information about blocks, as a stringified JSON blob in UDM.
   * @param {number} experimentId - UDM id of the experiment
   * @param {String} blockInfo stringified JSON blob.
   * @returns {Promise}
   */
  setBlockInfo(experimentId, blockInfo) {
    return this.rpc.sendRequest('set-block-info', { experimentId, blockInfo });
  }

  /**
   * Gets persisted block info. VSM would typically want to call this after passing in the xml UDM blob from its store for de-serialization.
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise<string>} will contain a stringified JSON blob that was persisted in UDM.
   */
  getBlockInfo(experimentId) {
    return this.rpc.sendRequest('get-block-info', { experimentId });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Array<DataMark>} objects containing information suitable for passing into DataMark constructors.
   */
  fetchAllDataMarks(experimentId) {
    return this.rpc.sendRequest('fetch-all-datamarks', { experimentId });
  }

  /**
   * Add a new datamark to the store.
   * @param {number} experimentId - UDM id of the experiment
   * @param { Object } dataMarkInfo contains a plain vanilla stringifiable object (See `DataMark.udmExport`)
   * @returns {Number} newly created datamark's id.
   */
  addDataMark(dataMarkInfo) {
    return this.rpc.sendRequest('add-datamark', dataMarkInfo);
  }

  _hackIdCounter = 1000;

  /**
   * Update an existing datamark.
   * @param {number} experimentId - UDM id of the experiment
   * @param {Ojbect} dataMarkInfo
   * @returns {Promise}
   */
  updateDataMark(params) {
    return this.rpc.sendRequest('update-datamark', params);
  }

  /**
   * Removes a data mark from any UDM stores
   * @param {number} experimentId - UDM id of the experiment
   * @param {Number} dataMarkId id of data mark to remove.
   */
  removeDataMark(params) {
    return this.rpc.sendRequest('remove-datamark', params);
  }

  /**
   * Formats meter info structs: converts text string Ids to ints.
   * @param {Object} info
   * @returns {Object} object with corrected field types.
   */
  _formatMeterInfo = info => {
    if (info.id) info.id = parseInt(info.id);
    if (info.columnId) info.columnId = parseInt(info.columnId);
    if (info.sensorId) info.sensorId = parseInt(info.sensorId);
    if (info.sensorMatchAutoId) info.sensorMatchAutoId = parseInt(info.sensorMatchAutoId);
    return info;
  };

  /**
   * Adds a meter to the udm store.
   * @param {Object} meterInfo conforms to MeterUdmInfo
   * @returns {Promise<Number>} udm id for the meter.
   * @throws {Error} describes what went wrong.
   */
  addMeter(meterInfo) {
    return this.rpc.sendRequest('udm:add-meter', this._formatMeterInfo(meterInfo));
  }

  /**
   * Retrieve meter info from the udm store
   * @param {number} experimentId - UDM id of the experiment
   * @param {Number} meterId udm identifier for the meter.
   * @returns {Promise<Object>} conforming to fields of MeterUdmInfo
   * @throws {Error} describes what went wrong.
   */
  getMeter(experimentId, meterId) {
    return this.rpc.sendRequest('udm:get-meter', { experimentId, id: meterId });
  }

  /**
   * Update meter info in the udm store.
   * @param {Object} info conforms to MeterUdmInfo. Must contain an `id` property
   * @returns {Promise} then() or await on success, else throws.
   * @throws {Error} describes what went wrong.
   */
  updateMeter(info) {
    return this.rpc.sendRequest('udm:update-meter', info);
  }

  /**
   * Remove a meter from the store
   * @param {number} experimentId - UDM id of the experiment
   * @param {Number} meterId udm identifier for the meter to remove.
   * @returns {Promise} then() or await on success, else throws.
   * @throws {Error} describes what went wrong.
   */
  removeMeter(params) {
    return this.rpc.sendRequest('udm:remove-meter', params);
  }

  /**
   * Returns all meter infos contained in the udm store
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise<Object[]>} array of meter infos conforming to MeterUdmInfo fields.
   * @throws {Error} describes what went wrong.
   */
  getAllMeters(experimentId) {
    return this.rpc.sendRequest('udm:get-all-meters', { experimentId });
  }

  /**
   * Returns number of points used for derivative calculations
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise<Number>}.
   * @throws {Error} describes what went wrong.
   */
  getDerivativePoints(experimentId) {
    return this.rpc.sendRequest('get-derivative-points', { experimentId });
  }

  /**
   * Sets number of points to use for derivative calculations
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise<>}.
   * @throws {Error} describes what went wrong.
   */
  setDerivativePoints(params) {
    return this.rpc.sendRequest('set-derivative-points', params);
  }

  /**
   * Returns number of points used for smoothing operations
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise<Number>}.
   * @throws {Error} describes what went wrong.
   */
  getSmoothingPoints(experimentId) {
    return this.rpc.sendRequest('get-smoothing-points', { experimentId });
  }

  /**
   * Sets number of points to use for smoothing operations
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise<>}.
   * @throws {Error} describes what went wrong.
   */
  setSmoothingPoints(params) {
    return this.rpc.sendRequest('set-smoothing-points', params);
  }

  /**
   * Fetches layout properties stored in the UDM Page section.
   * @param {number } experimentId UDM id of the experiment
   * @returns {Promise<LayoutFlags>} flags which can be used to update the
   * vstLayoutStore singleton on file->open.
   * @throws {Error} describes what went wrong.
   */
  getLayoutProperties(experimentId) {
    return this.rpc.sendRequest('get-layout-flags', { experimentId });
  }

  /**
   * Stores layout state, e.g. from `vstLayoutStore` in the udm document Page
   * section.
   * @param {number} experimentId UDM id of the experiment
   * @param {LayoutFlags} flags containing layout state.
   * @returns {Promise<>} success indicates
   * @throws {Error} describes what went wrong.
   */
  setLayoutProperties(experimentId, flags) {
    return this.rpc.sendRequest('set-layout-flags', { experimentId, ...flags });
  }

  /**
   * Sets or clears a column's frozen property.
   * @param {number} udmId udmId of the column to freeze.
   * @param {bool} freeze true to freeze, false to unfreeze.
   * @returns {Promise<>}
   * @throws {Error}
   */
  freezeColumn(udmId, freeze, experimentId) {
    return this.rpc.sendRequest('freeze-column', { udmId, freeze, experimentId });
  }

  /**
   * Retrieves all manual fits from the persistence store associated with a
   * given experiment id.
   * @param {number} experimentId current experiment id.
   * @returns {Promise<Object []>} resolves to array of objects which can be
   * imported into ManualFit objects, useful for re-populating the `manualFits`
   * array on file-open, else resolves with an error.
   */
  fetchAllManualFits(experimentId) {
    return this.rpc.sendRequest('get-all-manual-fits', { experimentId });
  }

  /**
   * Creates a new manual fit object in the persistence store.
   * @param {number} experimentId current experiment id.
   * @param {object} params an object returned by ManualFit.udmExport.
   * @returns {Promise<number>} udmId of the newly created manual fit object.
   * @throws {Error} with failure information
   */
  addManualFit(experimentId, params) {
    return this.rpc.sendRequest('add-manual-fit', { experimentId, ...params });
  }

  /**
   * Retrieves manual fit data from the persistence store for given id.
   * @param {number} experimentId current experiment id.
   * @param {number} fitId udm id of the fit to retrieve (as returned by
   * `addManualFit()` above).
   * @returns {Promise<Object>} resolves to an object which can be imported
   * into a ManualFit.
   * @throws {Error} with failure information
   */
  getManualFit(experimentId, fitId) {
    return this.rpc.sendRequest('get-manual-fit', { experimentId, fitId });
  }

  /**
   * Updates a manual fit's various properties in the persistence store.
   * @param {number} experimentId current experiment id.
   * @param {object} params an object returned by ManualFit.udmExport. It must
   * contain an `id` property.
   * @returns {Promise} which resolves if the operation was successful.
   * @throws {Error} with failure information
   */
  updateManualFit(experimentId, params) {
    return this.rpc.sendRequest('update-manual-fit', { experimentId, ...params });
  }

  /**
   * Remove a manual fit from the persistence store.
   * @param {number} experimentId current experiment id.
   * @param {number} fitId udm id of the manual fit to remove.
   * @returns {Promise} resolves if operation was successful.
   * @throws {Error} with failre information.
   */
  removeManualFit(experimentId, fitId) {
    return this.rpc.sendRequest('remove-manual-fit', { experimentId, id: fitId });
  }

  /**
   * Sync front end to back end worker queue.
   * @param {number} experiementId current id.
   * @returns {Promise} on resolve, all requests in the back end worker
   * queue prior to this call will have executed.
   */
  syncToBackEnd(experimentId) {
    return this.rpc.sendRequest('sync', { experimentId });
  }
}

/**
 * @typedef {Object} InfoBox
 * @property {number} x position of infobox
 * @property {number} y position of infobox
 * @property {boolean} isCollapsed indicates if box is collapsed or expanded.
 */

/**
 * @typedef {Object} LayoutFlags flags that mirror `vstLayoutStore` state.
 * @property {bool} graph_1
 * @property {bool} graph_2
 * @property {bool} graph_3
 * @property {bool} table
 * @property {bool} meter
 * @property {bool} video
 * @property {bool} notes
 * @property {bool} configurator
 */

/**
 * Control scope of document import
 * @typedef {Object} ExperimentSubset
 * @property {ExperimentSubsetGraphs} graphs
 * @property {ExperimentSubsetMeters} meters
 * @property {bool} graph_3
 */

/**
 * Flags to control scope of graph import
 * @typedef {Object} ExperimentSubsetGraphs
 * @property {bool} statistics
 * @property {bool} integrals
 * @property {bool} functions
 * @property {bool} gcIntegrals
 * @property {bool} fft
 * @property {bool} histogram
 * @property {bool} annotations
 * @property {bool} predictions
 * @property {bool} all - special flag indicating all of the above
 */

/**
 * Control scope of meter import
 * @typedef {Object} ExperimentSubsetMeters
 * @property {bool} all
 */
