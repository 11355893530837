// Promise-based wrapper for indexedDB for UdmArchive
//
// Creates/opens database called VstUdmData
// The database contains two identically keyed stores
// data -- contains raw file data (the xml part is stored as string, everything else Uint8Array)
// meta -- contains associated file meta data
//
// The reason for the meta store is so we can do certain operations
// without having to retrieve / change the large data chunks, currently
// the meta only stores the length of the file data, and the metadata for the xml doc
// is used to store the exportName property

const DATASTORE_NAME = 'FILE_DATA'; // 'data';
const METASTORE_NAME = 'meta';
// DATABASE_NAME and DATABASE_VERSION are needed for emscriptens wrapper around indexedDB and how it gets a lock on the database to use
const DATABASE_NAME = 'VstUdmData';
const DATABASE_VERSION = 22;

export class IndexedDB {
  static get name() {
    return DATABASE_NAME;
  }

  open() {
    const RETRY_COUNT_MAX = 10;
    const RETRY_INTERVAL = 250;

    return new Promise((resolve, reject) => {
      if (!window.indexedDB) {
        reject(new Error('IndexedDB not supported'));
      }

      // There's a bug in iOS 14.6 and Windows in which indexedDB does not start working until a few cycles
      // after it has first been invoked. To workaround this, I propose to delay the open until after
      // a small time period, and then try again for a certain number of attempts.
      let retryCount = 0;
      const timerId = setInterval(() => {
        this._openSequence().then(
          () => {
            clearInterval(timerId);
            resolve();
          },
          error => {
            clearInterval(timerId);
            reject(error);
          },
        );

        if (++retryCount >= RETRY_COUNT_MAX) {
          clearInterval(timerId);
          reject(new Error('indexedDB could not open database after several attempts.'));
        }
      }, RETRY_INTERVAL);
    });
  }

  // This is the general open sequence that we can call multiple times if the previous attempts time out.
  _openSequence() {
    return new Promise((resolve, reject) => {
      const req = window.indexedDB.open(DATABASE_NAME, DATABASE_VERSION);
      req.onerror = event => {
        reject(event.target.error);
      };

      req.onsuccess = event => {
        this.db = event.target.result;
        resolve();
      };

      req.onupgradeneeded = event => {
        const db = event.target.result;

        // this objectStore is from version 1 of our database, we want to remove it if it exists
        if (db.objectStoreNames.contains('data')) {
          db.deleteObjectStore('data');
        }

        // when upgrading we just delete the old data store and meta store since we don't
        // hold on to anything in the database on refresh anyway
        if (db.objectStoreNames.contains(DATASTORE_NAME)) {
          db.deleteObjectStore(DATASTORE_NAME);
        }

        if (db.objectStoreNames.contains(METASTORE_NAME)) {
          db.deleteObjectStore(METASTORE_NAME);
        }

        db.createObjectStore(DATASTORE_NAME); // file data
        db.createObjectStore(METASTORE_NAME, { keyPath: 'key' }); // asociated metadata, inline keys
      };

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-unused-vars
      req.onblocked = event => {
        // This should never happen because we should only have one connection to the database open per session, but
        // adding a handler is recommended for troublshooting purposes.
        reject(new Error('indexedDB is blocked.'));
      };
    });
  }

  set(key, value, meta = {}) {
    const promises = [];

    let p = new Promise((resolve, reject) => {
      const t = this.db.transaction([DATASTORE_NAME], 'readwrite');

      t.oncomplete = () => {
        resolve();
      };

      t.onerror = event => {
        reject(event.target.error);
      };

      t.objectStore(DATASTORE_NAME).put(value, key);
    });

    promises.push(p);

    p = new Promise((resolve, reject) => {
      const t = this.db.transaction([METASTORE_NAME], 'readwrite');

      t.oncomplete = () => {
        resolve();
      };

      t.onerror = event => {
        reject(event.target.error);
      };

      // Ensure correct length is stored with the metadata
      meta.length = value.length;
      meta.timestamp = Date.now();
      meta.key = key;
      t.objectStore(METASTORE_NAME).put(meta);
    });

    promises.push(p);

    return Promise.all(promises);
  }

  get(key) {
    return new Promise((resolve, reject) => {
      const t = this.db.transaction([DATASTORE_NAME]);
      const req = t.objectStore(DATASTORE_NAME).get(key);

      req.onerror = event => {
        reject(event.target.error);
      };

      req.onsuccess = () => {
        if (!req.result) {
          reject(new Error(`no key '${key}'`));
        } else {
          resolve(req.result);
        }
      };
    });
  }

  setMeta(key, meta) {
    // do a merge, ensuring we do not clobber the length param
    return this.getMeta(key).then(_meta => {
      const props = Object.keys(meta);
      props.forEach(p => {
        if (p !== 'length') {
          _meta[p] = meta[p];
        }
      });

      props.timestamp = Date.now;

      return new Promise((resolve, reject) => {
        const t = this.db.transaction([METASTORE_NAME], 'readwrite');

        t.oncomplete = () => {
          resolve(true);
        };

        t.onerror = event => {
          reject(event.target.error);
        };

        t.objectStore(METASTORE_NAME).put(_meta);
      });
    });
  }

  getMeta(key) {
    return new Promise((resolve, reject) => {
      const t = this.db.transaction([METASTORE_NAME]);
      const req = t.objectStore(METASTORE_NAME).get(key);

      req.onerror = event => {
        reject(event.target.error);
      };

      req.onsuccess = () => {
        if (!req.result) {
          reject(new Error(`no key '${key}'`));
        } else {
          resolve(req.result);
        }
      };
    });
  }

  // returns array of keys that match given prefix, or all keys if no
  // prefix is given
  keys(prefix) {
    return new Promise((resolve, reject) => {
      const t = this.db.transaction([DATASTORE_NAME]);
      const req = t.objectStore(DATASTORE_NAME).getAllKeys();

      req.onerror = event => {
        reject(event.target.error);
      };

      req.onsuccess = () => {
        if (prefix) {
          const keys = [];
          req.result.forEach(k => {
            if (k.startsWith(prefix)) {
              keys.push(k);
            }
          });
          resolve(keys);
        } else {
          resolve(req.result);
        }
      };
    });
  }

  remove(key) {
    const promises = [];

    let p = new Promise((resolve, reject) => {
      const t = this.db.transaction([DATASTORE_NAME], 'readwrite');

      t.oncomplete = () => {
        resolve();
      };

      t.onerror = event => {
        reject(event.target.error);
      };

      t.objectStore(DATASTORE_NAME).delete(key);
    });

    promises.push(p);

    p = new Promise((resolve, reject) => {
      const t = this.db.transaction([METASTORE_NAME], 'readwrite');

      t.oncomplete = () => {
        resolve();
      };

      t.onerror = event => {
        reject(event.target.error);
      };

      t.objectStore(METASTORE_NAME).delete(key);
    });

    promises.push(p);

    return Promise.all(promises);
  }

  clear(prefix) {
    if (!prefix) {
      const promises = [];

      let p = new Promise((resolve, reject) => {
        const t = this.db.transaction([DATASTORE_NAME], 'readwrite');

        t.oncomplete = () => {
          resolve();
        };

        t.onerror = event => {
          reject(event.target.error);
        };

        t.objectStore(DATASTORE_NAME).clear();
      });

      promises.push(p);

      p = new Promise((resolve, reject) => {
        const t = this.db.transaction([METASTORE_NAME], 'readwrite');

        t.oncomplete = () => {
          resolve();
        };

        t.onerror = event => {
          reject(event.target.error);
        };

        t.objectStore(METASTORE_NAME).clear();
      });

      promises.push(p);

      return Promise.all(promises);
    }

    return this.keys(prefix).then(keys => {
      const promises = [];
      keys.forEach(key => {
        promises.push(this.remove(key));
      });

      return Promise.all(promises);
    });
  }

  size() {
    return new Promise((resolve, reject) => {
      const t = this.db.transaction([METASTORE_NAME]);
      const req = t.objectStore(METASTORE_NAME).openCursor();
      let size = 0;
      const docs = {};

      req.onerror = event => {
        reject(event.target.error);
      };

      req.onsuccess = event => {
        const cur = event.target.result;
        if (cur) {
          // The xml fragment is stored as a string, JS strings take 2 bytes
          // per code unit, and String.length returns code units
          if (cur.key.endsWith('/data.udm')) {
            size += 2 * cur.value.length;
          } else {
            size += cur.value.length;
          }

          // Add a bit for the meta data itself -- assume it takes a single
          // 4k page, rather than trying to do an (imprecise) calculation
          size += 4 * 1024;

          const end = cur.key.lastIndexOf('/vstudm/data.udm');
          if (end >= 0) {
            const k = cur.key.substring(0, end);
            const i = parseInt(k);
            if (!Number.isNaN(i)) {
              docs[k] = {};
            }
          }

          cur.continue();
        } else {
          const documentCount = Object.keys(docs).length;
          resolve({ size, documentCount });
        }
      };
    });
  }

  // Prunes the database, leaving only 'leave' most recent records
  prune(leave = 1) {
    return new Promise((resolve, reject) => {
      const t = this.db.transaction([METASTORE_NAME]);
      const req = t.objectStore(METASTORE_NAME).getAll();

      req.onerror = event => {
        reject(event.target.error);
      };

      req.onsuccess = () => {
        if (!req.result || req.result.length === 0) {
          resolve();
        } else {
          const meta = req.result;
          const promises = [];

          // Sort in reverse order of timestamps
          meta.sort((m1, m2) => {
            const t1 = m1.timestamp || 0;
            const t2 = m2.timestamp || 0;

            // reverse order
            return t2 - t1;
          });

          // Delete anything except 'leave' most recent
          // records
          for (let i = leave; i < meta.length; i++) {
            promises.push(this.remove(meta[i].key));
          }

          Promise.all(promises).then(() => {
            resolve();
          });
        }
      };
    });
  }
}
