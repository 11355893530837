import { isFeatureFlagEnabled } from '@services/featureflags/featureFlags.js';

// JSON-RPC 2.0
const RPCErrorCode = {
  PARSE_ERROR: -32700, // Parse error Invalid JSON was received by the server.
  // An error occurred on the server while parsing the JSON text.
  INVALID_REQUEST: -32600, // Invalid Request The JSON sent is not a valid Request object.
  METHOD_NOT_FOUND: -32601, // Method not found  The method does not exist / is not available.
  INVALID_PARAMS: -32602, // Invalid params  Invalid method parameter(s).
  INTERNAL_ERROR: -32603, //  Internal error  Internal JSON-RPC error.
  // -32000 to -32099  Server error  Reserved for implementation-defined server-errors.
};
let _nextRequestId = 1;

let rpcLogger;

function _emptyCb() {}

function RPCMessageClient(msgChannel) {
  this.msgChannel = msgChannel;
  this.callbacks = {}; // list of callbacks
}

// params argument is optional
RPCMessageClient.prototype.sendRequest = function sendRequest(method, _params, _callback) {
  let callback = _callback;
  let params = _params;

  if (typeof params === 'function') {
    callback = params;
    params = null;
  }

  const jsonRPC = {
    id: _nextRequestId++,
    jsonrpc: '2.0',
    method,
    params,
  };

  this.callbacks[jsonRPC.id] = { callback, method } || { callback: _emptyCb, method };
  if (rpcLogger) rpcLogger.logRequest('FE', 'NM', jsonRPC);
  this.msgChannel.postMessage(jsonRPC);
};

RPCMessageClient.prototype.sendNotification = function sendNotification(method, params) {
  const jsonRPC = {
    jsonrpc: '2.0',
    method,
    params,
  };

  if (rpcLogger) rpcLogger.logNotification('FE', 'NM', jsonRPC);

  this.msgChannel.postMessage(jsonRPC);
};

RPCMessageClient.prototype._processResponse = function _processResponse(response) {
  if (String(response.jsonrpc) !== '2.0') {
    console.warn('Received non JSON-RPC 2.0 compliant message');
    // console.dir(response);
    return;
  }

  if (typeof response.id === 'number') {
    if (!this.callbacks[response.id]) {
      console.warn(`No callback for response.id=${response.id}`);
      console.dir(response);
      return;
    }
    const { callback, method } = this.callbacks[response.id];

    if (rpcLogger) rpcLogger.logResponse('NM', 'FE', method, response);

    if (typeof callback === 'function') {
      callback(response);
    } else {
      console.warn(`Unable to find callback for response.id=${response.id}`);
    }
    delete this.callbacks[response.id];
  } else {
    console.warn(`Received invalid response ID: ${response.id}`);
  }
};

// /////////////////////////////////////////////////////////////

function RPCMessageServer(msgChannel, methods) {
  this.msgChannel = msgChannel;

  // method signature:   function(reqId, params)
  this.methods = methods || {}; // list of method handlers
}

RPCMessageServer.prototype.addMethods = function addMethods(methods, methodContext = this) {
  // Add methodContext with actual method and add to this.methods
  // add to this.methods - method: function(reqId, params), context: methodContext
  if (methods) {
    Object.keys(methods).forEach(key => {
      // TODO: look into cleaning this up
      this.methods[key] = {};
      this.methods[key].method = methods[key];
      this.methods[key].context = methodContext;
    });
  } else {
    console.error('Incorrect params. Expected: methods');
  }
};

RPCMessageServer.prototype.sendResponse = function sendResponse(id, result) {
  const jsonRPC = {
    jsonrpc: '2.0',
    id,
    result,
  };

  if (rpcLogger) rpcLogger.logResponse('FE', 'NM', '', jsonRPC);

  this.msgChannel.postMessage(jsonRPC);
};

// message is optional
RPCMessageServer.prototype.sendErrorResponse = function sendErrorResponse(id, errCode, _message) {
  let message = _message;
  if (typeof message !== 'string') {
    message = 'Server Error';
    if (errCode === RPCErrorCode.PARSE_ERROR) {
      message = 'Parse error';
    } else if (errCode === RPCErrorCode.INVALID_REQUEST) {
      message = 'Invalid Request';
    } else if (errCode === RPCErrorCode.METHOD_NOT_FOUND) {
      message = 'Method not found';
    } else if (errCode === RPCErrorCode.INVALID_PARAMS) {
      message = 'Invalid Parameter(s)';
    } else if (errCode === RPCErrorCode.INTERNAL_ERROR) {
      message = 'Internal Error';
    }
  }

  const jsonRPC = {
    jsonrpc: '2.0',
    id,
    error: {
      code: errCode,
      message,
      // data
    },
  };

  // send an actual response, if the client sent a request
  if (typeof id === 'number') {
    if (rpcLogger) rpcLogger.logErrorResponse('NM', 'FE', '', jsonRPC);
    this.msgChannel.postMessage(jsonRPC);
  } else {
    // otherwise, this is a notification, so just log out a warning
    console.warn('RPC Error (response to notification)');
    // console.dir(jsonRPC);
  }
};

RPCMessageServer.prototype._processRequest = function _processRequest(request) {
  if (String(request.jsonrpc) !== '2.0') {
    console.warn('Received non JSON-RPC 2.0 compliant message');
    // console.dir(request);
    return;
  }

  try {
    if (typeof request.method === 'string') {
      const handler = this.methods[request.method];
      if (!handler) {
        throw new Error(`handler not found for method ${request.method}`);
      }
      const { method } = handler;

      if (typeof method === 'function') {
        if (rpcLogger) {
          if (request.id) rpcLogger.logRequest('NM', 'FE', request);
          else rpcLogger.logNotification('NM', 'FE', request);
        }

        method.call(handler.context, this, request);
      } else {
        this.sendErrorResponse(
          request.id,
          RPCErrorCode.METHOD_NOT_FOUND,
          `Method '${request.method}' not found`,
        );
      }
    } else {
      this.sendErrorResponse(null, RPCErrorCode.INVALID_REQUEST);
    }
  } catch (err) {
    console.error(`rpc failed when processing request: ${request.method}`);
    console.error(err);
  }
};

// full-duplex RPC Message Channel
function RPCMessageChannel(channel, methods) {
  this.channel = channel;
  this.methods = methods || {};

  if (isFeatureFlagEnabled('ff-nm-rpc-logging')) {
    import('./logger/RPCLogger.js')
      .then(({ RPCLogger }) => {
        // console.debug('Native Modules RPC Message Logging Enabled');
        rpcLogger = new RPCLogger();
      })
      .catch(e => {
        console.error(e);
      });
  }

  this.origin = window.location.origin; // TODO: parameterize this
  // console.log('RPCMessageChannel:protocol = ' + window.location.protocol);
  // console.log('RPCMessageChannel:origin   = ' + this.origin);
  if (window.location.protocol === 'chrome-extension:') {
    this.origin = '*';
  }

  this.server = new RPCMessageServer(this);
  this.client = new RPCMessageClient(this);

  /*
      // TODO: when should we remove this listener?
      this.channel.addEventListener('message', function(msg) {

          var data = msg.data;
          // TODO: fatal error if msg.data is not valid ?

          console.log("--- [JS] Message Received:");
          console.dir(data);


          // is this a request or a response?
          // demultiplex
          if (data.method) {
              this.server._processRequest(data)
          }
          else {
              this.client._processResponse(data);
          }
      }.bind(this));
  */
}

// This allows the channel to be setup ater this module has been instantiated
RPCMessageChannel.prototype.setChannel = function setChannel(channel) {
  this.channel = channel;
};

// methods can be registered on this channel from multiple modules
RPCMessageChannel.prototype.registerMethods = function registerMethods(methods, methodContext) {
  if (methods) {
    this.server.addMethods(methods, methodContext);
  } else {
    console.error('Inncorrect paramaters. Expected: methods');
  }
};

RPCMessageChannel.prototype.getClient = function getClient() {
  return this.client;
};
RPCMessageChannel.prototype.getServer = function getServer() {
  return this.server;
};
RPCMessageChannel.prototype.postMessage = function postMessage(msg) {
  // console.log('--- [JS] PostMessage:');
  // console.dir(msg);

  if (this.origin) {
    this.channel.postMessage(msg, this.origin);
  } else {
    this.channel.postMessage(msg);
  }
};

// code using RPCMessageChannel should bind to the 'message' event for the
// given channel and call this with the data field from the message to process
// an RPC message.  This functionality is not handled here because you typically
// bind to the window object and handle messages based on their origin.  This control
// is best left up to the application or another module that manages those events.
RPCMessageChannel.prototype.processMessage = function processMessage(msg) {
  // console.log('--- [JS] Message Received:');
  // console.dir(msg);

  // demultiplex: is this a request or a response?
  if (msg.method) {
    this.server._processRequest(msg);
  } else {
    this.client._processResponse(msg);
  }
};

function RPCResponseError(response) {
  const message = response.error.message || 'RPC Response Error';
  Error.call(this, message);
  this.response = response;
  this.error = response.error;
  this.message = message;
}
RPCResponseError.prototype = Object.create(Error.prototype);

// Convenience Functions
// Returns a Promise
RPCMessageChannel.prototype.sendRequest = function sendRequest(method, _params) {
  const { client } = this;
  let params = _params;
  return new Promise((resolve, reject) => {
    if (typeof params === 'undefined') {
      params = null;
    }
    client.sendRequest(method, params, response => {
      if (response.error) {
        reject(new RPCResponseError(response));
      } else {
        resolve(response.result);
      }
    });
  });
};

RPCMessageChannel.prototype.sendNotification = function sendNotification(...args) {
  const { client } = this;
  client.sendNotification(args);
};

RPCMessageChannel.prototype.sendResponse = function sendResponse(...args) {
  const { server } = this;
  server.sendResponse(args);
};

RPCMessageChannel.prototype.getLogString = () => {
  if (rpcLogger) return rpcLogger.getLogString();
  return '';
};

export const rpc = {
  RPCErrorCode,
  RPCMessageChannel,
  RPCMessageClient,
  RPCMessageServer,
};

/**
 * @typedef {RPCMessageChannel} RPCMessageChannel
 */
