import { importDataWorld, exportDataWorld } from './dataWorld.js';
import { importSensorWorld, exportSensorWorld } from './sensorWorld.js';
import { importDataCollection, exportDataCollection } from './dataCollection.js';
import { importDeviceManager, exportDeviceManager } from './deviceManager.js';

// special RPC call made by clients to query the host state
// unlike regular rpc calls, this doesn't go to native modules, but instead
// gets handled in the front-end
const getHostServiceState = async rpc => {
  return rpc.sendRequest('GET_HOST_STATE');
};

// special RPC call made by clients to notify that the host state
// has been restored in the client
const notifyClientStateRestored = rpc => {
  return rpc.sendRequest('CLIENT_STATE_RESTORED');
};

export const syncServices = async $services => {
  const { rpc, dataCollection, dataWorld, deviceManager, sensorWorld } = $services;

  const hostState = await getHostServiceState(rpc);

  try {
    await Promise.all([
      importDeviceManager(deviceManager, hostState.deviceManager),
      importSensorWorld(sensorWorld, hostState.sensorWorld),
      importDataWorld(dataWorld, hostState.dataWorld),
      importDataCollection(dataCollection, hostState.dataCollection),
    ]);
  } catch (e) {
    console.dir(e);
  }
  notifyClientStateRestored(rpc);
};

/**
 * Sets up a function to be called in the RPC layer for Host service state
 * @param {Object} $services the app services
 * @returns {Promise<function>} a function to be called in the RPC layer that returns the host services state
 */
export const setHostServices = $services => {
  const { dataCollection, dataWorld, deviceManager, sensorWorld } = $services;

  console.log('===== HOST SERVICE STATE (from host) =====');

  const state = {
    dataCollection: exportDataCollection(dataCollection),
    dataWorld: exportDataWorld(dataWorld),
    deviceManager: exportDeviceManager(deviceManager),
    sensorWorld: exportSensorWorld(sensorWorld),
  };

  return () => state;
};
