class _serializeHelper {
  constructor(fn) {
    this._func = fn;
  }

  async invoke(...args) {
    // If busy, cache the arguments.
    if (this._busy) {
      this._args.push(args);
      return;
    }

    // Not busy so call the function.
    this._busy = true;
    try {
      await this._func(...args);
    } catch (error) {
      console.error(error);
    }
    this._busy = false;

    // If we've any arguments cached, then invoke them one by one.
    if (this._args.length) {
      const args = this._args.shift();
      this.invoke(...args);
    }
  }

  _busy = false;

  _args = [];

  _func = () => {};
}

/**
 * Prevents an asynchronous function from being called before a prior invocation
 * has completed. Multiple overlapping calls to the function are effectively
 * serialized; each will execute with arguments intact after the previous call
 * completes.
 *
 * TODO (jkelly): when JS and our target browsers support decorators, we need
 * to create a `@serialized` decorator.
 *
 * @param {function} func the function you want to wrap.
 * @returns {function} wrapped function. Will prevent reentrant calls
 */
export function serializeFunction(func) {
  const serializer = new _serializeHelper(func);
  return (...args) => {
    serializer.invoke(...args);
  };
}
